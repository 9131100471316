.zoomed-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.zoomed-image {
    max-width: 90vw;
    max-height: 90vh;
    background-position: center !important;
}

.sensor-map{
    min-height: 250px;
    float: left;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &.previous-fp{
        background: url("./../../../assets/site/mdc/SensorMap-5F.jpeg");
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
    }
    &.current-fp{
        background: url("./../../../assets/site/mdc/SensorMap-5F-250325.jpeg");
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
    }
}

.custom-navbar{
    font-size: smaller;
    place-content: space-evenly;
}

.custom-pill {
    color: #808080; 
}
